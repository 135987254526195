import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { GlobalI18n } from '@settings/global-i18n';
import { Module } from '@shared/models/module-name';

@Injectable({
  providedIn: 'root',
})
export class AppUpdateVersionService {
  private readonly RELOAD_PAGE_TIME = 15000;
  constructor(private swUpdate: SwUpdate, private message: NzMessageService, private globalI18n: GlobalI18n) {
    // Проверка на наличие новой версии каждый час
    if (swUpdate.isEnabled) {
      interval(1 * 60 * 60 * 1000).subscribe(() => {
        this.swUpdate.checkForUpdate();
      });
    }

    this.swUpdate.versionUpdates.subscribe(evt => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.log(`Downloading new app version: ${evt.version.hash}`);
          break;
        case 'VERSION_READY':
          this.showUpdateMessage();
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.error(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
          break;
      }
    });
  }

  public checkForUpdates() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.checkForUpdate().then(updateIsExist => {
        if (updateIsExist) {
          this.showUpdateMessage();
        } else {
          console.log('Already on the latest version.');
        }
      });
    }
  }

  private showUpdateMessage(): void {
    this.message.loading(this.globalI18n.getMessage(Module.General, 'appUpdateTimer'), {
      nzDuration: this.RELOAD_PAGE_TIME,
    });
    setTimeout(() => {
      document.location.reload();
    }, this.RELOAD_PAGE_TIME);
  }
}
